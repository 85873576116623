<template>
    <div>
        <van-notice-bar text="本次中奖机会已超时，请与在线客服联系确认是否可用" v-if="activity.overtime" scrollable />
        <div class="container" v-if="loadSuccess && activity.goods_id" style="padding: 10px 0 100px;">
            <div style="box-shadow: 0 8px 12px #ebedf0;border-radius:10px;background: #fff;">
                <div style="font-size: 16px;font-weight: bold;padding: 10px 10px 10px 16px;border-bottom: 1px solid #ebedf0;text-align: center;color: rgb(145,215,28);display: flex;align-items: center;justify-content: center;">
                    <img src="http://lf-public.oss-cn-shenzhen.aliyuncs.com/lottery/success_icon.png" style="height: 24px;" alt=""/>
                    <span style="margin-left: 7px;">霸王餐中奖凭证</span>
                </div>
                <van-cell-group class="list-cell" style="overflow:hidden;border-radius: 0 0 10px 10px;">
                    <van-cell title="兑奖号码" :value="activity.code"/>
                    <van-cell title="团购名称" :value="activity.goods_name"/>
                    <van-cell title="团购价格" :value="'￥'+activity.price"/>
                    <van-cell title="店铺名称" :value="activity.shop_name"/>
                    <van-cell title="店铺地区" :value="activity.short_name"/>
                    <van-cell title="店铺地址" :value="activity.address"/>
                    <van-cell title="开奖时间" :value="activity.draw_time"></van-cell>
                    <van-cell title="有效期至" v-if="activity.overtime"><span style="color: red;font-weight: bold;">{{activity.consume_end_time}}(已过期)</span></van-cell>
                    <van-cell title="有效期至" v-if="!activity.overtime" :value="activity.consume_end_time"/>
                    <van-cell title="联系人" :value="activity.contact_man"/>
                    <van-cell title="联系电话"><a :href="'tel:'+ activity.contact_phone">{{activity.contact_phone}}</a></van-cell>
                </van-cell-group>
            </div>

            <div style="box-shadow: 0 8px 12px #ebedf0;border-radius:10px;background: #fff;">
                <div style="font-size: 14px;font-weight: bold;padding: 10px 10px 10px 16px;border-bottom: 1px solid #ebedf0;">
                    使用流程
                </div>
                <div style="padding: 10px 10px 10px 16px;">
                    <van-steps :active="0" direction="vertical" active-icon="clock">
                        <van-step>
                            <p style="margin: 0;"><span style="font-size: 16px;">①</span>&nbsp;领奖后请先联系来飞网在线客服，确认返现方法</p>
                        </van-step>
                        <van-step>
                            <p style="margin: 0;"><span style="font-size: 16px;">②</span>&nbsp;到店后正常使用团购券并核销</p>
                        </van-step>
                        <van-step>
                            <p style="margin: 0;"><span style="font-size: 16px;">③</span>&nbsp;拍照拍探店视频写点评</p>
                        </van-step>
                        <van-step>
                            <p style="margin: 0;"><span style="font-size: 16px;">④</span>&nbsp;提交点评内容，商家或在线客服返现</p>
                        </van-step>
                    </van-steps>
                </div>
            </div>
        </div>
        <van-goods-action style="z-index: 999;">
            <van-button style="margin-left:10px;height: 40px;background: #fff;" round text="返回首页" @click="$router.replace('/lottery')" />
            <van-goods-action-button style="border-radius: 999px;" type="danger" @click="telPhone" text="立即预约"/>
        </van-goods-action>
    </div>

</template>

<script>

    export default {
        name: "LotteryConsume",
        data() {
            return {
                time:3600,
                act_id: '',
                activity:{},
                loadSuccess:false,
            }
        },
        mounted() {
            this.act_id = this.$route.query.act_id;
            // 查询活动信息
            this.post('/api/client/activity/talent/consume/before', {
                act_id: this.act_id,
            }).then(result => {
                if (result.code === 0) {
                    this.activity = result.data;
                    this.loadSuccess = true;
                } else if (result.code === 1) {
                    this.$dialog.alert({
                        title: '提示',
                        message: result.msg,
                    }).then(() => {
                        this.$router.replace('/lottery');
                    });
                } else {
                    this.$toast(result.msg);
                    this.time = 0;
                }
            });
        },
        methods:{
            telPhone(){
                window.location.href='tel://' + this.activity.contact_phone;
            }
        },
    }
</script>

<style scoped>
    .text-center{
        text-align: center;
    }
    .container > div {
        margin: 10px;
        border: 1px solid #eee;
    }

    .list-cell .van-cell__title {
        flex: 0 0 75px;
    }

    .list-cell .van-cell__value {
        flex: 1 1 auto;
    }
</style>